<template>
	<div class="d-flex" style="z-index: 99;">
		<GMapMap
			:center="center"
			:zoom="zoom"
			:disableDefaultUI="true"
			:options="optionMap"
			ref="gmap"
			map-type-id="terrain"
			style="width: 100%; height: 96vh;"
		>
			<template v-if="vehicles.length > 0">
				<GMapMarker
					v-for="bus in vehicles"
					:key="bus.id"
					:icon="optionMarker(bus)"
					clickable
					:position="setPositionMarker(bus)"
					@click="clickVehicle(bus)"
				>
					<GMapInfoWindow
						closeclick
						@closeClick="focusVehicle = null"
						:opened="focusVehicle && focusVehicle.id === bus.id"
					>
						<MapInfoWindow v-if="focusVehicle" :bus="focusVehicle" />
					</GMapInfoWindow>
				</GMapMarker>
			</template>
		</GMapMap>
		<div class="position-absolute w-25 p-2" v-if="userIsAdmin">
			<div class="card bg-dark text-white">
				<div class="card-body">
					<div class="form-group">
						<label class="card-subtitle mb-2 text-muted" for="account">
							Vehicle Live Map
						</label>
						<select
							class="form-control"
							id="account"
							v-model="accountSelected"
							@change="changeAccountSelected"
						>
							<option
								v-for="account in accounts"
								:key="account.account_id"
								:value="account"
							>
								{{ account.account_name }}
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div
			class="position-absolute w-100 p-2 user-select-none"
			:style="{
				minHeight: '5vh',
				bottom: 0,
				maxHeight: '30vh',
				overflow: 'auto',
			}"
		>
			<div class="row">
				<div class="col-6">
					<DonatChart
						:label="'Status Vehicle'"
						:data="dashboard.status_vehicle"
					/>
				</div>
				<div class="col-6">
					<PieChart
						:label="'Top 10 Checkpoint'"
						:data="dashboard.top_checkpoint"
					/>
				</div>
				<div class="col-12">
					<div class="card bg-dark text-white">
						<div class="card-header bg-warning font-weight-bold">
							Recent Events
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12 pt-3 table-responsive">
									<Datatable
										v-bind:entries="dashboard.recent_events"
										:columns="recent_events"
										:currEntries="5"
										:title="'Recent Events'"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import VueGoogleMaps from "@fawmi/vue-google-maps";
	import { animateMarker, randomValueFromArray, isAdmin } from "../_helpers";
	import DonatChart from "../components/charts/DonatChart.vue";
	import PieChart from "../components/charts/PieChart.vue";
	import Datatable from "../components/Datatable.vue";
	import MapInfoWindow from "../components/MapInfoWindow.vue";
	export default {
		computed: {
			...mapState("dashboards", ["dashboard"]),
			...mapState("users", ["user"]),
			google: VueGoogleMaps,
			userIsAdmin() {
				return isAdmin(this.user);
			},
			optionMap() {
				return {
					zoomControl: false,
					mapTypeControl: false,
					scaleControl: true,
					streetViewControl: false,
					rotateControl: true,
					fullscreenControl: true,
					styles: this.styleDarkMode,
				};
			},
		},
		components: {
			PieChart,
			DonatChart,
			Datatable,
			MapInfoWindow,
		},
		data() {
			return {
				recent_events: [
					{
						name: "created_at",
						text: "Time",
						raw: {
							type: "Moment_UTC",
						},
						order: "desc",
					},
					{
						name: "vehicle",
						text: "Vehicle",
						raw: {
							type: "Sub",
							value: "vehicle_no",
						},
					},
					{
						name: "vehicle",
						text: "Vehicle Code",
						raw: {
							type: "Sub",
							value: "vehicle_code",
						},
					},
					{
						name: "driver",
						text: "Driver",
						raw: {
							type: "Sub",
							value: "name",
						},
					},
					{ name: "message", text: "Event" },
					{
						text: "Location",
						raw: {
							type: "gps_lat_lng",
						},
					},
				],
				center: { lat: -2.957870218506289, lng: 118.71762997438866 },
				defaultZoom: 5.2,
				zoom: 5.2,
				channelPusher: null,
				busIcon:
					"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
				vehicles: [],
				accounts: [],
				focusVehicle: null,
				accountSelected: null,
				styleDarkMode: [
					{ elementType: "geometry", stylers: [{ color: "#242f3e" }] },
					{
						elementType: "labels.text.stroke",
						stylers: [{ color: "#242f3e" }],
					},
					{ elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
					{
						featureType: "administrative.locality",
						elementType: "labels.text.fill",
						stylers: [{ color: "#d59563" }],
					},
					{
						featureType: "poi",
						elementType: "labels.text.fill",
						stylers: [{ color: "#d59563" }],
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [{ color: "#263c3f" }],
					},
					{
						featureType: "poi.park",
						elementType: "labels.text.fill",
						stylers: [{ color: "#6b9a76" }],
					},
					{
						featureType: "road",
						elementType: "geometry",
						stylers: [{ color: "#38414e" }],
					},
					{
						featureType: "road",
						elementType: "geometry.stroke",
						stylers: [{ color: "#212a37" }],
					},
					{
						featureType: "road",
						elementType: "labels.text.fill",
						stylers: [{ color: "#9ca5b3" }],
					},
					{
						featureType: "road.highway",
						elementType: "geometry",
						stylers: [{ color: "#746855" }],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [{ color: "#1f2835" }],
					},
					{
						featureType: "road.highway",
						elementType: "labels.text.fill",
						stylers: [{ color: "#f3d19c" }],
					},
					{
						featureType: "transit",
						elementType: "geometry",
						stylers: [{ color: "#2f3948" }],
					},
					{
						featureType: "transit.station",
						elementType: "labels.text.fill",
						stylers: [{ color: "#d59563" }],
					},
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [{ color: "#17263c" }],
					},
					{
						featureType: "water",
						elementType: "labels.text.fill",
						stylers: [{ color: "#515c6d" }],
					},
					{
						featureType: "water",
						elementType: "labels.text.stroke",
						stylers: [{ color: "#17263c" }],
					},
				],
			};
		},
		methods: {
			handleSubmit() {
				if (this.onRequest) return;
				const { route } = this;
				this.add(route);
			},
			...mapActions("dashboards", ["get_data"]),
			openSocket() {
				const pusher = this.$pusher;
				pusher.options.channelAuthorization.headers.Authorization = localStorage.getItem(
					"token"
				);
				this.channelPusher = pusher.subscribe(
					process.env.VUE_APP_PUSHER_CHANNEL_VEHICLE
				);
			},
			getColor(gps_position) {
				if (gps_position.speed > 0) {
					return "#3bb143";
				}
				if (
					gps_position &&
					gps_position.sensors &&
					gps_position.sensors.length > 0
				) {
					let acc = gps_position.sensors.filter((item) => item.type === "acc");
					if (acc.length > 0) {
						if (acc[0].val === true) {
							return "#007bff";
						}
					}
				}
				return "#ef5350";
			},
			optionMarker(bus) {
				if (typeof bus.gps_position === "undefined") return {};
				return {
					path: this.busIcon,
					size: { width: 60, height: 90, f: "px", b: "px" },
					scale: 0.7,
					rotation: parseInt(bus.gps_position.course),
					fillColor: this.getColor(bus.gps_position),
					fillOpacity: 1,
				};
			},
			setLocation(locations) {
				if (typeof locations !== "undefined") {
					locations.forEach((bus) => {
						const eventname = "location-" + bus.id;
						this.bindingEvent(eventname);
					});
				}
			},
			setPositionMarker(bus) {
				if (typeof bus.gps_position === "undefined") return {};
				return {
					lat: parseFloat(bus.gps_position.lat),
					lng: parseFloat(bus.gps_position.lng),
				};
			},
			bindingEvent(eventName) {
				if (this.channelPusher) {
					this.channelPusher.bind(eventName, (data) => {
						const index = this.accountSelected.vehicle_location.findIndex(
							(el) => {
								return el.id === data.id;
							}
						);
						if (index >= 0) {
							const timer = process.env.VUE_APP_RELOAD_TIME / 100;
							animateMarker(
								this.accountSelected.vehicle_location[index].gps_position,
								data.gps_position,
								timer
							);
							if (
								this.focusVehicle &&
								this.accountSelected.vehicle_location[index].id ===
									this.focusVehicle.id
							) {
								this.center = {
									lat: parseFloat(data.gps_position.lat),
									lng: parseFloat(data.gps_position.lng),
								};
							}
						}
						const indexParent = this.vehicles.findIndex((el) => {
							return el.account_id === this.accountSelected.account_id;
						});
						if (indexParent >= 0) {
							this.vehicles[indexParent] = this.accountSelected;
						}
					});
				}
			},
			clickVehicle(busdata) {
				if (this.zoom < 18) this.zoom++;
				this.focusVehicle = busdata;
			},
			changeAccountSelected() {
				if (this.accountSelected === null) return;

				this.vehicles = this.accountSelected.vehicle_location;
				const random = randomValueFromArray(this.vehicles);
				this.center = {
					lat: random.gps_position.lat,
					lng: random.gps_position.lng,
				};
			},
		},
		watch: {
			vehicles(val, prevVal) {
				if (
					typeof prevVal !== "undefined" &&
					prevVal.length > 0 &&
					val.length > 0
				) {
					this.channelPusher.unbind_all(); // close all binding event first
				}
				if (prevVal !== val) this.setLocation(val);
			},
			dashboard(next) {
				if (typeof next.vehicle_locations !== "undefined") {
					this.accounts = next.vehicle_locations;
				}
			},
			accounts(next) {
				if (next.length === 1 && !this.userIsAdmin) {
					this.accountSelected = next[0];
					this.changeAccountSelected();
				}
			},
			focusVehicle(next) {
				if (next !== null) {
					this.center = {
						lat: parseFloat(next.gps_position.lat),
						lng: parseFloat(next.gps_position.lng),
					};
				}
			},
		},
		async created() {
			this.$emit("onChildInit", "Dashboard", "");
			if (
				typeof this.user.account !== "undefined" &&
				this.user.account !== null
			) {
				await this.get_data(this.user.account.id);
			} else await this.get_data();
		},
		mounted() {
			this.openSocket();
		},
	};
</script>
